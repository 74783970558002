import Img from 'gatsby-image';
import React from 'react';

interface OwnProps {
    name: string;
    profession: string;
    image: any;
    email?: string;
    github?: string;
    linkedin?: string;
    twitter?: string;
    link?: string;
}

export const Avatar = (props: OwnProps) => {
    const onclick = (e: any) => {
        const el = e.target.closest('.avatar');

        const isTouchDevice = () => {
            return 'ontouchstart' in window || navigator.maxTouchPoints;
        };

        if (isTouchDevice() && el !== null) {
            if (!el.classList.contains('avatar--touch-hover')) {
                el.classList.add('avatar--touch-hover');
                e.preventDefault();
            } else {
                el.classList.remove('avatar--touch-hover');
            }

            let s = el.previousSibling;

            while (s !== null) {
                if (s.classList.contains('avatar--touch-hover')) {
                    s.classList.remove('avatar--touch-hover');
                }
                s = s.previousSibling;
            }

            s = el.nextSibling;

            while (s !== null) {
                if (s.classList.contains('avatar--touch-hover')) {
                    s.classList.remove('avatar--touch-hover');
                }
                s = s.nextSibling;
            }
        }
    };

    return (
        <div className="avatar" onClick={onclick}>
            <div className="avatar-content">
                <div className="avatar__image">
                    <Img alt={props.name} fluid={props.image} />
                </div>
                <div className="avatar__info">
                    <div className="info__name">
                        <strong>{props.name}</strong>
                        <br />
                        <em title={props.profession}>{props.profession}</em>
                    </div>
                </div>
            </div>
        </div>
    );
};
