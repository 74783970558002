import {FunctionComponent} from 'react';
import React from 'react';

import zeroSvg from '../images/logo-0.svg';
import threeSvg from '../images/logo-3.svg';
import fiveSvg from '../images/logo-5.svg';

// If gatsby builds we don't have a window
if (typeof window !== 'undefined') {
    function testForPassiveScroll() {
        let supportsPassiveOption = false;
        try {
            const opts = Object.defineProperty({}, 'passive', {
                get() {
                    supportsPassiveOption = true;
                },
            });
            const emptyListener = () => {
                /* Noop */
            };
            window.addEventListener('test', emptyListener, opts);
            window.removeEventListener('test', emptyListener, opts);
        } catch (e) {
            /* Noop */
        }
        return supportsPassiveOption;
    }

    let working = false;
    const handleScroll = () => {
        if (working === false) {
            working = true;
            window.requestAnimationFrame(() => {
                animateHeroGraphic('hero__graphic--three', 0.0007);
                animateHeroGraphic('hero__graphic--five', 0.0007);
                animateHeroGraphic('hero__graphic--zero', 0.0005);
                working = false;
            });
        }
    };

    const supportsPassive = testForPassiveScroll();
    window.addEventListener('scroll', handleScroll, supportsPassive ? {passive: true} : false);
    window.addEventListener('load', handleScroll);

    const windowHeight =
        (window || {}).innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    function animateHeroGraphic(elementId: string, scrollFactor: number) {
        const element = document.getElementById(elementId);
        const heroHeight = windowHeight - 200;
        if (element) {
            let style = 'transform: translateY(-' + heroHeight + 'px) translateZ(0)';
            const scroll = Math.floor(
                (2 * heroHeight) / (1 + Math.exp(-1 * scrollFactor * window.scrollY)) - heroHeight,
            );
            if (scroll < heroHeight) {
                style = 'transform: translateY(-' + scroll + 'px) translateZ(0)';
            }
            element.setAttribute('style', style);
        }
    }
}

interface OwnProps {
    location?: 'left' | 'right';
}

export const NumberHero: FunctionComponent<OwnProps> = ({location}) => {
    return (
        <>
            {location === 'left' ? (
                <div id="hero__graphic--five" className="hero__graphic">
                    <img src={fiveSvg} alt="Label305's 5" />
                </div>
            ) : (
                <div id="hero__graphic--three" className="hero__graphic">
                    <img src={threeSvg} alt="Label305's 3" />
                </div>
            )}

            <div
                id="hero__graphic--zero"
                className={location === 'left' ? 'hero__graphic hero__graphic--left' : 'hero__graphic'}>
                <img src={zeroSvg} alt="Label305's 0" />
            </div>
        </>
    );
};
