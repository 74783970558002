import {graphql, Link} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import {Avatar} from '../../components/avatar';
import {Button} from '../../components/button';
import {WillAnimate} from '../../components/content_animate';
import {InAnchorLink} from '../../components/in_anchor_link';
import {avatarImage, fluid1200Width, fluid800Width, fullscreenHero, Layout, logoImage} from '../../components/layout';
import {NumberHero} from '../../components/number_hero';
import {ScrollTo} from '../../components/scroll_to';

const locale = 'en';

export default ({data}: any) => {
    return (
        <Layout
            title="The engineering consultancy for software products"
            description="Software product consultancy from the Netherlands. Since 2010 we realise modern and easy-to-use software products for our clients."
            ogImage="homepage-en.png"
            locale={locale}
            translatedPagePath="/">
            <WillAnimate as="div" name="hero" className="hero--home" id="hero">
                <div className="hero__content-wrapper">
                    <div className="hero__content">
                        <h1>
                            Creating <br /> products <br /> together
                        </h1>
                        <h2>
                            We are software engineers <br /> who put the end user first.
                        </h2>
                    </div>
                </div>
                <ScrollTo className="hero__scroll" target="/en/#from-hero" position="start" />

                <NumberHero />
            </WillAnimate>

            <section className="section">
                {/* TECHNIC STRATEGIC PARTNER */}
                <WillAnimate id="from-hero">
                    <div className="container">
                        <div className="content__display">
                            <h2>
                                Technical <br /> strategic <br /> partner
                            </h2>
                        </div>
                        <div className="content__copy">
                            <p className="p-lead">
                                Since 2010, we’ve been creating and improving software&shy;products{' '}
                                <span className="highlight">together</span> with our clients. We act as a genuine
                                technical and strategic partner for the long term.
                            </p>
                            <p>
                                We produce modern, complex, scalable software products that are{' '}
                                <span className="highlight">easy to use</span>. Such as{' '}
                                <span className="highlight">web platforms</span> and{' '}
                                <span className="highlight">SaaS products</span>.
                            </p>
                            <p>
                                We work in a continuous process, with the client taking the role of{' '}
                                <span className="highlight">product owner</span>.
                            </p>
                        </div>
                        <div className="content__buttons content__buttons--50">
                            <Button url="/en/approach/" name="Our approach" />
                        </div>
                    </div>
                </WillAnimate>

                {/* CASES */}
                <article className="content">
                    <div className="container">
                        <Link
                            to="/en/portfolio/saas-product-keeping"
                            title="Case: Our own product: Keeping"
                            className="work-item-wrapper">
                            <WillAnimate as="div" className="work-item work-item--odd">
                                <div className="work-item__background" />
                                <div className="work-item__image">
                                    <Img alt="Keeping" fluid={data.keepingBarend.childImageSharp.fluid} />
                                </div>

                                <div className="work-item__content-wrapper">
                                    <div className="work-item__title">
                                        <h3>Our own SaaS product: Keeping</h3>
                                    </div>
                                    <div className="work-item__subtitle">
                                        <h4>Time-tracking for every business.</h4>
                                    </div>
                                    <div className="work-item__copy">
                                        <p>
                                            Get your time organised with{' '}
                                            <InAnchorLink
                                                name="Keeping"
                                                url="https://keeping.nl/ontdek-urenregistratie"
                                                title="Keeping time tracking (Dutch)"
                                            />
                                            , the time-tracking application. Keeping keeps the basics simple, but is
                                            versatile in use.
                                        </p>
                                    </div>
                                    <Button name="View this work" />
                                </div>
                            </WillAnimate>
                        </Link>

                        <Link
                            to="/en/portfolio/valuation-platform-taxapi"
                            title="Case: Real estate valuation platform TaXapi"
                            className="work-item-wrapper">
                            <WillAnimate as="div" className="work-item work-item--even work-item--last">
                                <div className="work-item__background" />
                                <div className="work-item__image">
                                    <Img
                                        alt="TaXapi Appraisal Platform in use"
                                        fluid={data.taxapiMain.childImageSharp.fluid}
                                    />
                                </div>

                                <div className="work-item__content-wrapper">
                                    <div className="work-item__title">
                                        <h3>Property valuation with TaXapi</h3>
                                    </div>
                                    <div className="work-item__subtitle">
                                        <h4>Everything for valuating, at your fingertips.</h4>
                                    </div>
                                    <div className="work-item__copy">
                                        <p>
                                            Together with real estate valuation experts, we developed TaXapi. The
                                            platform makes carrying out a valuation a quick, straightforward and
                                            pleasant experience for both the valuer and the customer.
                                        </p>
                                    </div>
                                    <Button name="View this work" />
                                </div>
                            </WillAnimate>
                        </Link>
                    </div>
                </article>

                {/* CREATE NEW SOFTWARE */}
                <WillAnimate className="content">
                    <div className="container">
                        <div className="content__display">
                            <h2>
                                Developing <br />
                                software <br />
                                together
                            </h2>
                        </div>
                        <div className="content__copy">
                            <p className="p-lead">
                                Got an idea for a new software product? We’d be pleased to put our expertise to work to
                                help you.
                            </p>
                            <p>
                                We specialise in creating web applications using{' '}
                                <span className="highlight">Laravel</span>, <span className="highlight">React</span> and{' '}
                                <span className="highlight">node.js</span>. In doing so, we also realize{' '}
                                <span className="highlight">APIs</span> and{' '}
                                <span className="highlight">web services</span>. We set up scalable,{' '}
                                <span className="highlight">privacy-conscious</span> application infra&shy;structures
                                with modern (cloud) providers.
                            </p>

                            <p>
                                When creating software products, we always put the user’s needs first. We devote extra
                                attention to the user experience and{' '}
                                <span className="highlight">Privacy&nbsp;by&nbsp;Design</span> is a principle we use in
                                every decision.
                            </p>
                        </div>

                        <div className="content__buttons content__buttons--50">
                            <Button url="/en/approach/" name="Our approach" />
                        </div>
                    </div>
                </WillAnimate>

                {/* OFFICE */}
                <WillAnimate className="content">
                    <div className="container">
                        <div className="content__display content__display--avatars">
                            <h2>
                                From our office in Enschede, we work in a small team of true specialists. In this
                                informal environment, we create software products.
                            </h2>
                        </div>
                        <div className="avatar-grid">
                            <div className="avatar-grid__background" />

                            <Avatar
                                name="Xander"
                                profession="Founder, UX Engineer"
                                image={data.peopleXander.childImageSharp.fluid}
                            />

                            <Avatar
                                name="Olav"
                                profession="Founder, UX Engineer"
                                image={data.peopleOlav.childImageSharp.fluid}
                            />

                            <Avatar
                                name="Nick"
                                profession="Software Engineer"
                                image={data.peopleNick.childImageSharp.fluid}
                            />

                            <Avatar
                                name="Jaap"
                                profession="Software Engineer"
                                image={data.peopleJaap.childImageSharp.fluid}
                            />

                            <Avatar
                                name="Irene"
                                profession="Office Manager"
                                image={data.peopleIrene.childImageSharp.fluid}
                            />

                            <Avatar
                                name="Dirk-Jan"
                                profession="Software Engineer"
                                image={data.peopleDirk_jan.childImageSharp.fluid}
                            />

                            <Avatar
                                name="Jeroen"
                                profession="Software Engineer"
                                image={data.peopleJeroen.childImageSharp.fluid}
                            />
                        </div>
                    </div>
                </WillAnimate>

                {/* SOFTWARE CONSULTANCY */}
                <WillAnimate className="content">
                    <div className="container">
                        <div className="content__display">
                            <h2>
                                Technical and <br /> strategic <br /> advice
                            </h2>
                        </div>
                        <div className="content__copy">
                            <p className="p-lead">
                                Do you have an existing software product? We are happy to advise and support you in
                                solving both <span className="highlight">technical</span> and{' '}
                                <span className="highlight">UX</span> issues.
                            </p>
                            <p>
                                Perhaps you want to develop an attractive, efficient user experience? Or to add a unique
                                but complex feature that will set your product apart from the rest? We can help you
                                achieve this.
                            </p>
                            <p>
                                Your product belongs to you: now and in the future. Whether it’s a new product or an
                                existing one, the <span className="highlight">intellectual property</span> rights always
                                transfer to you automatically.
                            </p>
                        </div>
                        <div className="content__buttons content__buttons--50">
                            <Button url="/en/contact/" name="Contact us" />
                        </div>
                    </div>
                </WillAnimate>

                {/* BRANDS */}
                <WillAnimate className="content">
                    <div className="container">
                        <div className="content__display content__display--logos">
                            <h2>
                                We work with national and international clients on software products for many sectors
                                including technology, auto&shy;motive, education, tele&shy;communications, tourism,
                                government and real estate.
                            </h2>
                        </div>
                        <div className="logos-grid">
                            <div className="logos-grid__background" />
                            <div className="logos-grid__row">
                                <div className="logos-grid__item">
                                    <Img alt="Logo TaXapi" fluid={data.taxapi.childImageSharp.fluid} />
                                </div>
                                <div className="logos-grid__item">
                                    <Img alt="Logo Autosociaal" fluid={data.autosociaal.childImageSharp.fluid} />
                                </div>
                                <div className="logos-grid__item">
                                    <Img alt="Logo Gripvol" fluid={data.gripvol.childImageSharp.fluid} />
                                </div>
                                <div className="logos-grid__item">
                                    <Img
                                        alt="Logo 360 Sportsintelligence"
                                        fluid={data.sportsintelligence.childImageSharp.fluid}
                                    />
                                </div>
                            </div>

                            <div className="logos-grid__row">
                                <div className="logos-grid__item">
                                    <Img alt="Logo TravelTrustIt" fluid={data.traveltrustit.childImageSharp.fluid} />
                                </div>
                                <div className="logos-grid__item">
                                    <Img alt="Logo Itslearning" fluid={data.itslearning.childImageSharp.fluid} />
                                </div>
                                <div className="logos-grid__item">
                                    <Img
                                        alt="Logo Vertaalbureau Perfect"
                                        fluid={data.vertaalbureauPerfect.childImageSharp.fluid}
                                    />
                                </div>
                                <div className="logos-grid__item">
                                    <Img alt="Logo 123 Test" fluid={data.test.childImageSharp.fluid} />
                                </div>
                            </div>
                        </div>
                    </div>
                </WillAnimate>

                {/* JOBS */}
                <WillAnimate className="content">
                    <div className="container">
                        <div className="content__display">
                            <h2>
                                Interested <br />
                                working at <br />
                                Label305?
                            </h2>
                        </div>
                        <div className="content__copy">
                            <p className="p-lead">
                                Are you keen to work on software with a high level of technical detail? If you’re
                                creative and love variety, then working at Label305 could be the challenge you’re
                                looking for.
                            </p>
                            <p>
                                We are an enthusiastic, young, hard-working team with an informal, fun working climate.
                                We’re not too big and we plan to stay that way.
                            </p>
                            <p>
                                We offer room for <span className="highlight">technical specialisation</span>,{' '}
                                <span className="highlight">self-development</span> and work on{' '}
                                <span className="highlight">our own products</span> We offer a healthy mix of{' '}
                                <span className="highlight">working together</span> in the office, and from home.
                            </p>
                        </div>
                        <div className="content__buttons content__buttons--50">
                            <Button url="https://careers.305.nl/?lang=en" name="All vacancies and internships" />
                        </div>
                    </div>
                </WillAnimate>
            </section>
        </Layout>
    );
};

export const query = graphql`
    query {
        keepingBarend: file(relativePath: {eq: "portfolio/keeping/keeping-barend.jpg"}) {
            ...fluid800Width
        }
        taxapiMain: file(relativePath: {eq: "portfolio/taxapi/taxapi-main.jpg"}) {
            ...fluid800Width
        }
        autosociaal: file(relativePath: {eq: "logos/autosociaal-black@2x.png"}) {
            ...logoImage
        }
        gripvol: file(relativePath: {eq: "logos/gripvol-black@2x.png"}) {
            ...logoImage
        }
        sportsintelligence: file(relativePath: {eq: "logos/360-black@2x.png"}) {
            ...logoImage
        }
        itslearning: file(relativePath: {eq: "logos/itslearning-black@2x.png"}) {
            ...logoImage
        }
        test: file(relativePath: {eq: "logos/123-black@2x.png"}) {
            ...logoImage
        }
        taxapi: file(relativePath: {eq: "logos/taxapi-black@2x.png"}) {
            ...logoImage
        }
        traveltrustit: file(relativePath: {eq: "logos/traveltrustit-black@2x.png"}) {
            ...logoImage
        }
        vertaalbureauPerfect: file(relativePath: {eq: "logos/vertaalbureau-perfect-black@2x.png"}) {
            ...logoImage
        }
        patrick: file(relativePath: {eq: "logos/patrick-black@2x.png"}) {
            ...logoImage
        }
        peopleXander: file(relativePath: {eq: "people/xander_2.jpg"}) {
            ...avatarImage
        }
        peopleOlav: file(relativePath: {eq: "people/olav_2.jpg"}) {
            ...avatarImage
        }
        peopleNick: file(relativePath: {eq: "people/nick_2.jpg"}) {
            ...avatarImage
        }
        peopleJaap: file(relativePath: {eq: "people/jaap_2.jpg"}) {
            ...avatarImage
        }
        peopleIrene: file(relativePath: {eq: "people/irene_2.jpg"}) {
            ...avatarImage
        }
        peopleDirk_jan: file(relativePath: {eq: "people/dirk_jan_2.jpg"}) {
            ...avatarImage
        }
        peopleJeroen: file(relativePath: {eq: "people/jeroen_2.jpg"}) {
            ...avatarImage
        }
    }
`;
