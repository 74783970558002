import {FunctionComponent} from 'react';
import React from 'react';

interface OwnProps {
    name: string;
    url?: string;
    title?: string;
    className?: string;
}

/**
 * Only use this when a link is into another <a>
 * This is bad for SEO
 */
export const InAnchorLink: FunctionComponent<OwnProps> = ({name, url, title, className}) => {
    function onClick(e: any) {
        e.preventDefault();
        window.open(url, '_blank');
    }
    return (
        <span className={className ? className : 'link'} title={title} onClick={e => onClick(e)}>
            {name}
        </span>
    );
};
